th {
    background-color: red;
}
/* tr {
    color: black;
    font-size: .8em;
} */

.okTickMark {
    color: lightgreen;
    background-color: lightgreen;
}