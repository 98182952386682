* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

.content {
    width: 100%;
    height: auto;
    /*min-height: 100vh;*/
}

.title {
    padding-top: 1em;
}
/* @media screen and (min-width: 280px) and (max-width: 768px) {
    .columnFaqs {
    display: none;
    }
} */
/* @media screen and (min-width: 480px) {
    .columnFaqs {
        border-right: 1px solid black;
    }
}
 */

 .home-page-div-one {
    margin: 0;
    padding: 2em;
    text-align: center;
    background-image: url(../../images/oshcimage.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    /* background-attachment: fixed;
 */    
    color: black;
    width: 100%;
    height: 330px;
    /* backdrop-filter: blur(50px);
    z-index: -1;
 } */
}

.home-page-div-two {
    margin: 0;
    padding: 2em;
    text-align: center;
    background-image: url(../../images/ovhcimage.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    /* background-attachment: fixed;
 */    
    color: black;
    width: 100%;
    height: 330px;
    /* backdrop-filter: blur(50px);
    z-index: -1;
 } */
}

.home-page-div-one .request-a-call-back:hover {
    cursor: pointer;
}

.home-page-div-one .request-a-call-back {
    text-decoration: underline red;
}

/* .blur-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(10px);
    z-index: -1;
  } */
.anchor-tag:hover {
    cursor: pointer;
}

.ahm-logo:hover {
    cursor: pointer;
}
 