/* BlinkingButton.css */

@keyframes blinking-animation {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  .blinking-btn {
    animation: blinking-animation 3.5s infinite;
  }
  