/* Tabs.css */

/* .tabs-container {
    display: flex;
  }
  
  .tab {
    padding: 10px 20px;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    cursor: pointer;
    margin-right: 5px;
  }
   */
   .tab-faq:hover {
    background-color: green;
   }
  .tab:hover {

    background-color: red;
    /* background-color: #ddd; */
    /* Add any other style changes on hover here */
  }
  