/* Sidebar.css */

.sidebar {
    position: fixed;
    top: 0;
    left: 0;
    width: 250px;
    height: 100%;
    background-color: #f0f0f0;
    padding: 20px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  }
  
  .sidebar ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .sidebar li {
    margin-bottom: 10px;
    cursor: pointer;
  }
  
  /* Optional: Add any additional styling for the sidebar items */
  