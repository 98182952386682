@media screen and (min-width: 480px) {
    .columnFaqs {
        border-right: 1px solid black;
    }
}
@media screen and (min-width: 576px)  {
    .faq-container {
        display: none;
    }
}

.logo-images:hover {
    cursor: pointer;
}
/* @media screen and (min-width: 180px) {
    .chat-on-whatsapp-button {
        margin-top: 1em;
    }
} */
/* @media screen and (min-width: 768px) {
    .chat-on-whatsapp-button {
        display: hidden;
    }
} */

.cover-options-button-columns {
 background-color: red;   
 color: white;
 border: none; 
 border-radius: 8px; 
 padding-top: 1em;
 padding-bottom: 1em;
 margin: 1em;
 z-index: 1;
}
.cover-options-button-columns:hover {
    cursor: pointer;
}