.dropbtn {
    /* background-color: #4CAF50; */
    background-color: transparent;
    color: #3B5998;
    padding: 16px;
    font-size: 16px;
    border: none;
    cursor: pointer;
    
    /* position: relative; */
  }
  
  .dropdown {
    position: relative;
    display: inline-block;
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    /* right: 1.5em; */
    /* left: -150%; */
    left: -135%;
    margin-top: .2em;
    text-align: left;    
    background-color: #f9f9f9;
    min-width: 330px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }
  .dropdown-content .font-size {
    font-size: 1.2em;
  }
  @media screen and (min-width: 180px) and (max-width: 280px) {
    .dropdown-content {
      min-width: auto;
      left: 0;
    }
    .dropdown-content .font-size {
      font-size: .9em;
    }
  }
  @media screen and (min-width: 280px) and (max-width: 320px) {
    .dropdown-content {
      min-width: 200px;
      left: -25%;
    }
    .dropdown-content .font-size {
      font-size: .9em;
    }
  }
  @media screen and (min-width: 320px) and (max-width: 380px) {
    .dropdown-content {
      min-width: 230px;
      left: -25%;
    }
    .dropdown-content .font-size {
      font-size: .9em;
    }
  }
  @media screen and (min-width: 380px) and (max-width: 420px) {
    .dropdown-content {
      min-width: 230px;
      left: -30%;
    }
    .dropdown-content .font-size {
      font-size: .9em;
    }
  }
  @media screen and (min-width: 420px) and (max-width: 447px) {
    .dropdown-content {
      min-width: 260px;
      left: -40%;
    }
    .dropdown-content .font-size {
      font-size: .9em;
    }
  }
  @media screen and (min-width: 447px) and (max-width: 574px) {
    .dropdown-content {
      min-width: 280px;
      left: -75%;
    }
    .dropdown-content .font-size {
      font-size: 1em;
    }
  }

  @media screen and (min-width: 575px) and (max-width: 594px) {
    .dropdown-content {
      min-width: 280px;
      left: -50%;
    }
    .dropdown-content .font-size {
      font-size: 1em;
    }
  }
  @media screen and (min-width: 595px) and (max-width: 793px) {
    .dropdown-content {
      min-width: 280px;
      left: -100%;
    }
    .dropdown-content .font-size {
      font-size: 1em;
    }
  }
  /* @media screen and (min-width: 793px) and (max-width: 951px) {
    .dropdown-content {
      left: -50%;
    }
    
  } */
  @media screen and (min-width: 793px) and (max-width: 951px) {
    .dropdown-content {
      
      left: -75%;
    }
    
  }
  @media screen and (min-width: 951px) and (max-width: 980px) {
    .dropdown-content {
      left: -75%;
    }
    
  }
  .dropdown-content a {
    color: #3B5998;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  
  .dropdown-content a:hover {
    cursor: pointer;
  }
  /* .dropdown-content a:hover {background-color: #f1f1f1}
   */
  .dropdown:hover .dropdown-content {
    display: block;
    border: 1px solid whitesmoke;
    border-radius: 5px;
  }
  
  /* .dropdown:hover .dropbtn {
    background-color: #3e8e41;
  } */