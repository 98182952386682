.card-clients-feedback {
    background-color: red;
    border: none;
    
}

.card-2--clients-feedback {
    background-color: white;
    border: none;
    
    
}

.cardbody-clients-feedback {
    color: 'white';
}