.oshc-div-with-background-image {
    margin: 0;
    padding: 2em;
    text-align: center;
    background-image: url(../../images/iamstudyinginaustralia.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    /* background-attachment: fixed;
 */    
    color: black;
    width: 100%;
    height: 500px;
    /* Add the blur effect */
  /* filter: blur(8px);
  -webkit-filter: blur(8px); */
    /* backdrop-filter: blur(50px);
    z-index: -1;
 } */
}