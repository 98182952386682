
/* .tab-content {
  display: none;
} */

.tab-item {
  position: relative;
  text-decoration: none;
  color: black; 
}

.tab-item::before {
  content: '';
  position: absolute;
  width: 100%;
  /* height: 2px; */
  height: 4px;
  bottom: -2px;
  left: 0;
  background-color: red;
  font-weight: bolder;
  transform: scaleX(0);
  transition: transform 0.2s ease-in-out;

  
  font-size: .5em;
}

.tab-item:hover::before {
  font-weight: bolder;
  transform: scaleX(1);
  /* display: block; */
}

/* App.css */
/* .custom-dropdown {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.custom-dropdown {
  width: 100%;
}
 */
/* .tab-item:hover .tab-content {
  display: block;
} */

/* @media screen and (min-width: 576px) {
  .tab-item-faqs {
    display : 'none';
  }
} */