@media screen and (min-width: 180px) and (max-width: 220px) {
    .logo-image {
        margin-left: 30%;
    }
    
    .responsive-css-join-facebook, .responsive-css-whatsapp-button {
        margin-left: 25%;
    }
    
    
}

@media screen and (min-width: 220px) and (max-width: 260px) {
    .logo-image {
        margin-left: 32%;
    }
    
    .responsive-css-join-facebook, .responsive-css-whatsapp-button {
        margin-left: 25%;
    }
    
    
}
@media screen and (min-width: 261px) and (max-width: 280px) {
    .logo-image {
        margin-left: 34%;
    }
}
@media screen and (min-width: 281px) and (max-width: 300px) {
    .logo-image {
        margin-left: 40%;
    }
}
@media screen and (min-width: 301px) and (max-width: 320px) {
    .logo-image {
        margin-left: 48%;
    }
}
@media screen and (min-width: 321px) and (max-width: 340px) {
    .logo-image {
        margin-left: 50%;
    }
}
@media screen and (min-width: 341px) and (max-width: 360px) {
    .logo-image {
        margin-left: 55%;
    }
}
@media screen and (min-width: 361px) and (max-width: 386px) {
    .logo-image {
        margin-left: 60%;
    }
}

@media screen and (min-width: 386px) {
    .logo-image {
        margin-left: 5%;
    }
}



@media screen and (min-width: 261px) and (max-width: 280px) {
    
    
    .responsive-css-join-facebook, .responsive-css-whatsapp-button {
        margin-left: 30%;
    }
    
    
}

@media screen and (min-width: 281px) and (max-width: 300px) {
    
    
    .responsive-css-join-facebook, .responsive-css-whatsapp-button {
        margin-left: 35%;
    }
    
    
}

@media screen and (min-width: 300px) and (max-width: 320px) {
    
    
    .responsive-css-join-facebook, .responsive-css-whatsapp-button {
        margin-left: 40%;
    }
    
    
}

@media screen and (min-width: 321px) and (max-width: 340px) {
    
    
    .responsive-css-join-facebook, .responsive-css-whatsapp-button {
        margin-left: 46%;
    }
    
    
}

@media screen and (min-width: 341px) and (max-width: 360px) {
    
    
    .responsive-css-join-facebook {
        margin-left: 63%;
    } 
    
    .responsive-css-whatsapp-button {
        margin-left: 56%;
    }
    
    
}

@media screen and (min-width: 361px) and (max-width: 370px) {
    
    
    .responsive-css-join-facebook {
        margin-left: 67%;
    } 
    
    .responsive-css-whatsapp-button {
        margin-left: 60%;
    
    }
    
}
@media screen and (min-width: 371px) and (max-width: 386px) {
    
    
    .responsive-css-join-facebook {
        margin-left: 70%;
    }
    .responsive-css-whatsapp-button {
        margin-left: 62%;
    }
    
    
}

/* @media screen and (min-width: 38px) {
    
    
    .responsive-css-join-facebook {
        margin-right: 5%;
    }
    .responsive-css-whatsapp-button {
        margin-right: 8%;
    }
     */
    

/* @media screen and (min-width: 400px) and (max-width: 430px) {
    .responsive-css-join-facebook, .responsive-css-whatsapp-button {
        margin-left: 67%;
    }
    
    
}

@media screen and (min-width: 431px) and (max-width: 466px) {
    .responsive-css-join-facebook {
        margin-left: 80%;
    } 
    
    .responsive-css-whatsapp-button {
        margin-left: 75%;
    }
    
    
} */