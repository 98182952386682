@media screen and (min-width: 280px) and (max-width: 768px) {
   .form-control {
    font-size: .5em;
   }
}
@media screen and (min-width: 280px) and (max-width: 380px) {
    .contact-form {
        margin-left: 1%;
        margin-right: 1%;

        
    }
}
@media screen and (min-width: 380px) and (max-width: 480px) {
    .contact-form {
        margin-left: 4%;
        margin-right: 4%;

       
    }
}
@media screen and (min-width: 480px) and (max-width: 530px) {
    .contact-form {
        margin-left: 7%;
        margin-right: 7%;

       
    }
}
@media screen and (min-width: 530px) and (max-width: 580px) {
    .contact-form {
        margin-left: 10%;
        margin-right: 10%;

       
    }
}
@media screen and (min-width: 580px) and (max-width: 630px) {
    .contact-form {
        margin-left: 13%;
        margin-right: 13%;

       
    }
}
@media screen and (min-width: 580px) and (max-width: 680px) {
    .contact-form {
        margin-left: 5.5%;
        margin-right: 5.5%;

       
    }
}
@media screen and (min-width: 680px) and (max-width: 768px) {
    .contact-form {
        margin-left: 4.5%;
        margin-right: 4.5%;

       
    }
}
/* @media screen and (min-width: 768px) {
    .contact-form {
        margin-left: 27%;
        margin-right: 27%;
        border: 3px solid rgb(85, 82, 82);
        border-radius: 10px;
        padding: 1.5em;
        color: black;
    } 
}
*/