.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


@media screen and (min-width: 180px) and (max-width: 576px) {
  .columnFaqs {
    display: none;
  }
}


.right-arrow-symbol {
  
  color: red;  
  font-size: 2em;
}

.custom-header {
  background-color: #f7f7f7;
  border: 1px solid #ddd;
}

/* Custom styles for accordion header button */
.custom-header .btn {
  color: #333;
  font-weight: bold;
}

.accordion-faqs p {
  font-size: .8em;
  /* font-weight: bold; */
}

.accordion-faqs ul {
  list-style-type: none;
  
}
.accordion-faqs ul li {
  position: relative;
}

.accordion-faqs ul li:before {
  content: "•"; /* Bullet character (can be other characters or an image) */
  color: red; /* Change this to the desired color */
  position: absolute;
  left: -15px; /* Adjust this value to control the bullet's position */
  font-size: 1.2em;
  font-weight: bolder;
}
/* .accordion-faqs ul li::before {
  content: "\2022";
  color: red;
  
  
} */
.modal-ovhc-anchor:hover {
  cursor: pointer;
}
